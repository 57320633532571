import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Link,
  Typography,
} from '@mui/material';
import { FC, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DragItem, dropHover } from '@/utils';
import { useSelector } from 'react-redux';
import { getFeedByIdModSelector } from '@/features/mod/mod.selector';

interface CardProps {
  addonId: string;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  onRemove: () => void;
}

export const AddonItem: FC<CardProps> = ({ addonId, index, moveItem, onRemove }) => {
  const addon = useSelector((state) => getFeedByIdModSelector(state, addonId));
  const ref = useRef<HTMLTableRowElement>(null);
  const [_0, drop] = useDrop<DragItem, void>({
    accept: 'compilationAddon',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover: dropHover(ref, index, moveItem),
  });

  const [_1, drag] = useDrag({
    type: 'compilationAddon',
    item: () => {
      return { id: addonId, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (!addon) {
    return (
      <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  drag(drop(ref));

  return (
    <Card ref={ref} sx={{ display: 'flex' }}>
      <CardMedia
        sx={{ width: '19rem' }}
        component="img"
        image={addon.pictures[0].url}
        alt={addon.pictures[0].originalName}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="h5" component="div">
            {addon.name.ru}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {addon.name.en}
          </Typography>
          <Typography variant="body2">{addon.desc.ru}</Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => onRemove()}>
            Удалить из списка подборки
          </Button>
          <Button component={Link} href={`/mod/edit/${addon.id}`} target="_blank" rel="noopener noreferrer">
            Редактировать
          </Button>
        </CardActions>
      </Box>
    </Card>
  );
};
